import axios from 'axios'
import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 8000 // request timeout
})

// 设置请求次数，请求的间隙
service.defaults.retry = 4;
service.defaults.retryDelay = 1000;

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (!res.success) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (error.response) {
      console.log('err' + error) // for debug
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }else{
      // 处理超时的情况
      let config = error.config
      // If config does not exist or the retry option is not set, reject
      if(!config || !config.retry) return Promise.reject(error)
   
      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0
   
      // Check if we've maxed out the total number of retries
      if(config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(error)
      }
   
      // Increase the retry count
      config.__retryCount += 1
   
      // Create new promise to handle exponential backoff
      let backoff = new Promise(function(resolve) {
        setTimeout(function() {
          resolve()
        }, config.retryDelay || 1)
      })
   
      // Return the promise in which recalls axios to retry the request
      return backoff.then(function() {
        return service(config)
      })
    
    }
  }
)

export default service
