import connextAppABI from '@/abis/connext_app.json'
import connextErc20ABI from '@/abis/connext_erc20.json'
const config = {
  // baseChain:{
  //   chainId: 97, // 目标链ID
  //   chainName: 'BNB Smart Chain Testnet',
  //   nativeCurrency: {
  //     name: 'tBNB',
  //     symbol: 'tBNB',
  //     decimals: 18
  //   },
  //   rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'], // 节点
  //   blockExplorerUrls: ['https://testnet.bscscan.com/']
  // },
  baseChain:{
    chainId: 56,
    chainName: 'BNB Smart Chain',
    nativeCurrency: {
      name: 'BSC',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc.nodereal.io'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  connextAppAddress: '0xd48387263fd0b7F1f03b06b790efb8b913969BB3',
  connextAppABI: connextAppABI,
  connextErc20Address: '0x1b2128AbC4119474D16Bb0a04200B63B0e68A971',
  connextErc20AddressIndex: '0x1b2128AbC4119474D16Bb0a04200B63B0e68A971', // 首页显示token地址
  connextErc20ABI: connextErc20ABI,
  totalSupply:'100,000,000',
  isOpen: true
};

export default config;
