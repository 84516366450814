<template>
  <div class="main-header-containter">
    <div class="section-menu">
      <img src="../../assets/images/logo.png" @click="toRouter('Home')" style="cursor: pointer;"/>
      <div class="menu">
        <div :class="{item:true,active:activeMenu=='Jobs'}" @click="toRouter('jobs')">Jobs</div>
        <div :class="{item:true,active:activeMenu=='Talent'}" @click="toRouter('talent')">Talent</div>
        <div :class="{item:true,active:activeMenu=='Profile'}" @click="toRouter('myProfile')">My profile</div>
      </div>
      <div class="connect-btn" @click="connectMetaMask()" :disabled="loadings.metaMaskLoading"  v-if="currentAccount == null || (currentAccount != null && tips.accountBtnTips != '')"><span v-if="!loadings.metaMaskLoading">Connect Wallet</span><span v-if="loadings.metaMaskLoading">Initializing...</span></div>
      <div class="address-btn" v-if="currentAccount != null && tips.accountBtnTips == ''" @mouseenter="showAddressInfo = true" >
        <div class="address">{{ currentAccount | formatAddress }}</div>
        <i class="el-icon-switch-button" @click="logout()"></i>
      </div>
      <!-- <div v-if="currentAccount != null && tips.accountBtnTips != ''" class="tips">{{tips.accountBtnTips}}</div> -->
    </div>
    <div class="section-banner">
      <img src="../../assets/images/logo3.png" class="img1"/>
      <img src="../../assets/images/touming1.png" class="img2"/>
      <img src="../../assets/images/logo1.png" class="img3"/>
    </div>

    <el-dialog
      :visible.sync="switchDialogVisible"
      :show-close="false"
      custom-class="switch-dialog">
      <div slot="title" class="dialog-title">
        <img src="../../assets/images/cloae.svg" @click="dismiss"/>
      </div>
      <div class="title">You Must Change Networks</div>
      <div class="remark">We’ve detected that you need switch your wallet’s network to BNB Chain for this Dapp.</div>
      <span slot="footer" class="dialog-footer">
        <div class="cancel-btn" @click="dismiss">Dismiss</div>
        <div class="confirm-btn" @click="doSwitchWallet">Switch Wallet</div>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import config from "../../config";
import detectMetamaskProvider from "@metamask/detect-provider";
export default {
  props: ['activeMenu'],
  data() {
    return{
      currentAccount:null,
      tips: {
        metaMaskTips: "",
        accountBtnTips: "",
      },
      loadings: {
        mintLoading:false,
        metaMaskLoading: false,
      },
      switchDialogVisible: false
    }
  },
  filters: {
    formatAddress(address) {
      if (address) {
        return (
          address.substring(0, 6) +
          "..." +
          address.substring(address.length - 4)
        );
      }
    }
  },
  created: function () {
    this.currentAccount = window.localStorage.getItem("LOGIN_ACCOUNT_ADDRESS");
    this.$emit('update:currentAccount', this.currentAccount)
    this.currentProvider = window.localStorage.getItem("LOGIN_PROVIDER");
    if (this.currentAccount == "undefined") this.currentAccount = null;
    if (window.ethereum && window.ethereum.isConnected()) {
      this.initMetamask()
      // this.doInitAccount()
    }
  },
  methods: {
    toRouter(path){
      this.$router.push({name:path})
    },
    async initMetamask() {
      const that = this
    
      window.ethereum.on("accountsChanged", (accounts) =>
        that.handleAccountsChanged(accounts,that)
      );

      let chainId =await window.ethereum.request({ method: 'eth_chainId' });
      chainId = new this.Web3(window.ethereum).utils.hexToNumber(chainId);
      this.handleChainChanged(chainId);
      window.ethereum.on('chainChanged', (chainId)=>this.handleChainChanged(chainId));
    },
    logout() {
      console.log(this)
      if (this.currentAccount != null) {
        window.localStorage.removeItem("LOGIN_ACCOUNT_ADDRESS");
        window.localStorage.removeItem("LOGIN_PROVIDER");
        this.currentAccount = null;
        this.$emit('update:currentAccount', null)
        this.currentProvider = null;
      }
    },
    async connectMetaMask() {
      const that = this
      if (this.loadings.metaMaskLoading) return;
      this.loadings.metaMaskLoading = true;
      const provider = await detectMetamaskProvider();
      if (provider) {
        let chainId = await window.ethereum.request({ method: 'eth_chainId' })
        chainId = new this.Web3(window.ethereum).utils.hexToNumber(chainId);
        if (chainId != config.baseChain.chainId){
          // this.$confirm('We’ve detected that you need switch your wallet’s network to BNB Chain for this Dapp.', 'You Must Change Networks', {
          //   distinguishCancelAndClose: true,
          //   confirmButtonText: 'Switch Wallet',
          //   cancelButtonText: 'Dismiss'
          // })
          //   .then(async() => {
          //     let checkChainResult = await that.checkChain()
          //     console.log(checkChainResult)
          //     if(checkChainResult){
          //       that.doInitAccount()
          //     }else{
          //       this.loadings.metaMaskLoading = false
          //     }
          //   })
          //   .catch(action => {
          //     if(action === 'cancel'){
          //       this.loadings.metaMaskLoading = false
          //       return
          //     }
          //   })
          this.switchDialogVisible = true
        }else{ 
          that.doInitAccount()
        }
        
        
        
      } else {
        this.$message({
          showClose: true,
          message: 'You need install MetaMask first',
          type: 'error'
        })
      }
    },
    dismiss(){
      this.loadings.metaMaskLoading = false
      this.switchDialogVisible = false
    },
    async doSwitchWallet(){
      const that = this
      that.switchDialogVisible = false
      let checkChainResult = await that.checkChain()
      console.log(checkChainResult)
      if(checkChainResult){
        that.doInitAccount()
      }else{
        this.loadings.metaMaskLoading = false
      }
      
    },
    async doInitAccount() {
      console.log("doinitAccount")
      const that = this
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => this.handleAccountsChanged(accounts,that))
        .catch((err) => {
          if (err.code === 4001) {
            this.tips.metaMaskTips = "Please connect to MetaMask.";
            this.loadings.metaMaskLoading = false;
          } else {
            console.error(err);
            this.loadings.metaMaskLoading = false;
          }
        })

      await this.initMetamask()
    },
    async checkChain(){
      let checkChainResult = false
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{
          chainId: new this.Web3(window.ethereum).utils.numberToHex(config.baseChain.chainId) // 目标链ID
        }]
      }).then(() => {
        checkChainResult = true
      }).catch(async(e) => {
        if (e.code === 4902) {
           let basechainParam = config.baseChain
           basechainParam.chainId = new this.Web3(window.ethereum).utils.numberToHex(basechainParam.chainId)
           await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              basechainParam
            ]
          }).then(async()=> {
            checkChainResult = true
          })
        }else if(e.code === 4001){
          checkChainResult = false
        }
      })
      return checkChainResult
      // try {
      //   await window.ethereum.request({
      //     method: 'wallet_switchEthereumChain',
      //     params: [{
      //       chainId: new this.Web3(window.ethereum).utils.numberToHex(97) // 目标链ID
      //     }]
      //   }).then
      // } catch (e) {
      //   console.log(e)
      //   if (e.code === 4902) {
      //     try {
      //       await window.ethereum.request({
      //           method: 'wallet_addEthereumChain',
      //           params: [
      //             {
      //               chainId: new this.Web3(window.ethereum).utils.numberToHex(97), // 目标链ID
      //               chainName: 'BNB Smart Chain Testnet',
      //               nativeCurrency: {
      //                 name: 'tBNB',
      //                 symbol: 'tBNB',
      //                 decimals: 18
      //               },
      //               rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'], // 节点
      //               blockExplorerUrls: ['https://testnet.bscscan.com/']
      //             }
      //           ]
      //         })
      //     } catch (ee) {
      //       console.log(ee)
      //       //
      //     }
      //   } else if (e.code === 4001) return
      // }
    },
    handleAccountsChanged(accounts,that) {
      if (accounts.length === 0) {
        that.logout()
        this.tips.metaMaskTips = "Please connect to MetaMask.";
        this.loadings.metaMaskLoading = false;
      } else {
        this.tips.metaMaskTips = "";
        // var loginAccountAddress = window.localStorage.getItem(
        //   "LOGIN_ACCOUNT_ADDRESS"
        // );
        if (that.currentAccount == null || accounts[0] != that.currentAccount) {
          window.localStorage.setItem("LOGIN_ACCOUNT_ADDRESS", accounts[0]);
          window.localStorage.setItem("LOGIN_PROVIDER", "metamask");
          that.currentAccount = accounts[0];

          that.$emit('update:currentAccount', that.currentAccount)
          this.connectToWalletDialog = false;

        }
        this.loadings.metaMaskLoading = false;
      }
    },
    handleChainChanged(chainId) {

      if (chainId != config.baseChain.chainId) {
        // this.tips.accountBtnTips = "Wrong Network";
        this.currentAccount = null;
        this.$emit('update:currentAccount', this.currentAccount)
        window.localStorage.removeItem("LOGIN_ACCOUNT_ADDRESS")
      } else {
        this.tips.accountBtnTips = "";
      }
    },  
    getWeb3(provider) {
      if (provider == "metamask")
      { 
        
        return new this.Web3(window.ethereum);
      }
      if(provider)
        window.ethereum.enable();
      return new this.Web3(window.ethereum);
    }
  }
};
</script>

<style lang="scss">
.main-header-containter{
  .section-menu{
    height:51px;
    margin-left:120px;
    margin-right:120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    img{
      width:79.5px
    }
    .menu{
      width:130px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      font-size: 8px;
      font-family: Gilroy-Bold, Gilroy;
      font-weight: bold;
      color: #132054;
      line-height: 13px;
      .item{
        cursor:pointer;
      }
      .active::after{
        content: '';
        width: 50%;
        height: 3px;
        display: block;
        margin: 0 auto;
        background: linear-gradient(90deg, #0FBAED 0%, #AA50FE 100%);
        border-radius: 13px 13px 13px 13px;
      }
      .item:hover::after{
        content: '';
        width: 50%;
        height: 3px;
        display: block;
        margin: 0 auto;
        background: linear-gradient(90deg, #0FBAED 0%, #AA50FE 100%);
        border-radius: 13px 13px 13px 13px;
      }
    }
    .connect-btn{
      width: 74px;
      height: 19px;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      border: 1px solid #132054;
      font-size: 7px;
      font-family: Gilroy-Bold, Gilroy;
      font-weight: bold;
      color: #132054;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
    }
    .address-btn{
      font-size: 7px;
      font-family: Gilroy-Bold, Gilroy;
      font-weight: bold;
      color: #132054;
      line-height: 19px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      .address{
        margin-right:5px;
      }
      i{
        cursor: pointer;
      }
    }
  }
  .section-banner{
    width:100%;
    height:81px;
    background: #000;
    position: relative;
    img{
      position:absolute;
    }
    img.img1{
      width:61.99px;
      left:144.5px;
      top:15px;
    }
    img.img2{
      width:129.1px;
      left:195.5px;
      top:-46px;
    }
    img.img3{
      width:87.5px;
      right:183px;
    }
  }
}
.switch-dialog{
  width: 490px;
  background: #F2F5F8;
  border-radius: 20px 20px 20px 20px;
  .dialog-title{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    img{
      width:9px;
      height:9px;
      cursor: pointer;
    }
  }

  .dialog-footer{
    display: flex;
    flex-direction: row;
    justify-content: right;
    .cancel-btn{
      cursor: pointer;
      width: 94px;
      height: 16px;
      line-height:16px;
      text-align: center;
      border-radius: 13px 13px 13px 13px;
      border: 1px solid #CCCCCC;
      font-size: 8px;
      font-family: Gilroy-Bold, Gilroy;
      font-weight: bold;
      color: #132054;
      margin-right:11px;
    }
    .confirm-btn{
      cursor: pointer;
      width: 94px;
      height: 16px;
      line-height:16px;
      text-align: center;
      background: linear-gradient(90deg, #0FBAED 0%, #AA50FE 100%);
      border-radius: 13px 13px 13px 13px;
      opacity: 1;
      font-size: 8px;
      font-family: Gilroy-Bold, Gilroy;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .title{
    font-size: 12px;
    font-family: Gilroy-Bold, Gilroy;
    font-weight: bold;
    color: #000000;
    line-height: 14px;
  }
  .remark{
    font-size: 8px;
    font-family: Gilroy-Regular, Gilroy;
    font-weight: 400;
    color: #686868;
    line-height: 14px;
  }

  .el-dialog__body{
    padding-top:0px;
    padding-bottom:21px;
  }
}
</style>