<template>
  <div class="main-footer-containter">
    © 2022 Connex. All rights reserved.
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return{

    }
  }
};
</script>

<style lang="scss">
.main-footer-containter{
  font-size: 7px;
  font-family: Gilroy-Regular, Gilroy;
  font-weight: 400;
  color: #000000;
  line-height: 11px;
  margin-right:120px;
  text-align: right;
  margin-bottom:26.5px;
  margin-top:53.5px;
}
.el-message-box{
  font-size: 12px !important;
  font-family: Gilroy-Regular, Gilroy;
}
.el-message-box__title{
  font-size: 12px !important;
  font-family: Gilroy-Regular, Gilroy;
}
.el-message-box__content{
  font-size: 12px !important;
  font-family: Gilroy-Regular, Gilroy;
}
.el-button{
  font-size: 12px !important;
  font-family: Gilroy-Regular, Gilroy;
}
</style>